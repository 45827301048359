import { Outlet, RouteObject } from "react-router-dom";
import { ListManagement } from "./Lists/Lists";
import { List } from "./List/List";
import { EmailManagementDashboard } from "./EmailManagementDashboard/EmailManagementDashboard";
import { Reports } from "./Reports/Reports";
import { Report } from "./Report/Report";
import { segmentManagementPageRoute } from "./ReportSegments/ReportSegments";

export const emailManagementPageRoute: RouteObject = {
    path: 'email-management',
      handle: 'Email',
      element: <EmailManagement />,
      children: [
        {
          index: true,
          path: '',
          handle: 'Dashboard',
          element: <EmailManagementDashboard />,
        },
        
        {
          path: 'lists',
          handle: 'Lists',
          element: <ListManagement />,
        },
        {
            index: true,
            path: 'list/:id',
            handle: 'List',
            element: <List />
        },
        {
            path: 'reports',
            handle: 'Reports',
            element: <Reports />,
        },
        {
            index: true,
            path: 'email-report/:id',
            handle: 'Email Report',
            element: <Report reportType="email"  />,
        },
        {
            index: true,
            path: 'dashboard-report/:id',
            handle: 'Dashboard Report',
            element: <Report reportType="dashboard"  />,
        },
        segmentManagementPageRoute,
      ]
    };


export function EmailManagement(props: any) {
    return (<>
        <Outlet />
    </>);
}