import { ReportSegment } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function usePotentialReportSegments(reportType): [ReportSegment[] | undefined, boolean] {
  const [potentialReportSegments, setPotentialReportSegments] = useState<any[] | undefined>(undefined);
  const [potentialReportSegmentsLoading, setPotentialReportSegmentsLoading] = useState(false);

  useEffect(() => {
    if (potentialReportSegmentsLoading || potentialReportSegments !== undefined) {
      return;
    }
    setPotentialReportSegmentsLoading(true);

    apiService
      .getApi()
      .admin.emailReportControllerGetPotentialReportSegments()
      .then((response) => {
        setPotentialReportSegments(response.data);
        setPotentialReportSegmentsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setPotentialReportSegmentsLoading(false);
      });
  }, [potentialReportSegments, potentialReportSegmentsLoading, reportType]);

  return [potentialReportSegments, potentialReportSegmentsLoading];
}
